import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { addMinutes, addSeconds } from 'date-fns';
import { resetStorage } from 'utils/localStorage';

//CHECK TOKEN
export const isExpired = (token: string | null) => {
  if (token === null) return true;
  else {
    try {
      const decoded: { exp: number } = jwt_decode(token);
      const currDate = addMinutes(new Date(), -30); //30 min earlyer
      const expDate = addSeconds(new Date(1970, 0, 1, 1, 0, 0), decoded.exp);
      if (expDate < currDate) {
        resetStorage();
        return true;
      }
    } catch (error) {
      resetStorage();
      return true;
    }
  }
  return false;
};

const ProtectedRoute = ({ children, exact, path, component }: RouteProps) => {
  const appState = useLocalStorage()[0];
  const [tokenExpired, setTokenExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const pageState = usePageVisibility();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const newCheck = isExpired(appState.token);
    if (newCheck !== tokenExpired) setTokenExpired(isExpired(appState.token));
    // eslint-disable-next-line
  }, [pageState, loading, appState.token, appState.account]);

  if (tokenExpired) {
    return <Redirect to='/login' />;
  }

  if (!loading) {
    if (tokenExpired) {
      return <Redirect to='/login' />;
    } else if (
      appState.account &&
      appState.account?.oneTimeSetupComplete === false
    ) {
      return <Redirect to='/initial-password-setup' />;
    } else {
      return (
        <Route exact={exact} path={path} component={component}>
          {children}
        </Route>
      );
    }
  }
  return <> </>;
};

export default ProtectedRoute;
