export const stringsRS = {
  // generics
  generic: {
    cancel: 'Anulare SRB',
    forms: {
      firstName: 'Prenume SRB',
      lastName: 'Nume SRB',
      language: 'Limba SRB',
      phone: 'Număr de telefon SRB',
      country: 'Country SRB',
      doterraId: 'doTERRA ID SRB',
      email: 'Adresa de email SRB',
      password: 'Parola SRB',
      typeHere: 'Type here SRB',
      search: 'Search SRB',
    },
  },
  // avatar dropdown header
  hello: 'Salut SRB',
  myAccount: 'Contul meu SRB',
  userManagement: 'Gestionarea utilizatorilor SRB',
  contentManagement: 'Content Management SRB',
  newProduct: 'New Product SRB',
  newProtocol: 'New Protocol SRB',
  newEvent: 'New Event SRB',
  newEduCare: 'New eduCare Article SRB',
  newBlend: 'New Blend of the Month SRB',
  contact: 'Contact SRB',
  logout: 'Deconectați-vă SRB',

  // navigation
  products: 'Produse SRB',
  protocols: 'Protocoale SRB',
  resources: 'Resurse SRB',
  bookings: 'Programări SRB',
  eduCare: 'eduCare SRB',
  consultation: 'Consultations SRB',
  events: 'Evenimente SRB',
  search: 'Cautare SRB',
  login: 'Autentificare SRB',
  changeLanguage: 'Schimbați limba SRB',
  // login page
  loginTitle: 'Autentificare membru SRB',
  loginSubtitle: 'O lume plină de magie și uleiuri esențiale doTERRA SRB',
  typeEmail: 'Introduceți adresa de email SRB',
  typePassword: 'Introduceți parola SRB',
  forgotPassword: 'Ați uitat parola? SRB',
  registerLink: 'Nu ai cont? SB', //new
  buttonLogin: 'Autentificare SRB',

  // my account page
  myAccountPage: {
    doterraId: 'ID-ul doTERRA al utilizatorului SRB',
    changePassword: 'Schimbați parola SRB',
    update: 'Actualizați informațiile SRB',
    erros: {
      firstNameRequired: "First name can't be empty SRB",
      firstMin: 'First name must be at least 2 characters SRB',
      firstMax: 'First name must be at most 20 characters SRB',
      lastNameRequired: "Last name can't be empty SRB",
      lastMin: 'Last name must be at least 2 characters SRB',
      lastMax: 'Last name must be at most 20 characters SRB',
      countryRequired: "Country can't be empty SRB",
      phoneRequired: "Phone can't be empty SRB",
    },
  },
  //userManagement page
  usersManagement: {
    addUser: '+ Add new SRB',
    all: 'Toate SRB',
    search: 'Search SRB',
    showing: {
      pre: 'Se afișează SRB',
      user: 'utilizator SRB',
      mid: 'out of SRB',
      plural: 'de utilizatori SRB',
      none: 'Nici un utilizator SRB',
    },
    pagination: {
      show: 'Show SRB',
      page: 'Page sRB',
      of: 'of SRB',
    },
    table: {
      name: 'Full name SRB',
      country: 'Country SRB',
      submissionDate: 'Form submission date SRB',
      assignedEnroller: 'Assigned enroller SRB',
      doTERRAID: 'doTERRA ID SRB',
      accountType: 'Account type SRB',
      status: 'Status SRB',
    },
  },

  // initial password setup page
  initialPasswordPage: {
    title: 'Bine ați venit pe Wonderland Essentials SRB',
    subtitle: 'Haideți să creăm parola contului dumneavoastră. SRB',
    yourPassword: 'Parolă dumneavoastră SRB',
    placeholder: 'Tastați aici SRB',
    confirmPassword: 'Confirmare parolă SRB',
    terms: {
      accept: 'I accept the SRB',
      terms: 'Terms and Conditions SRB',
    },
  },
  // change password page
  chooseNewPasswordPage: {
    title: 'Alegeți o parolă nouă SRB',
    passwordRequirements:
      'Parolele trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin 1 cifră, 1 literă mare, 1 literă mică și 1 caracter special. SRB',
    oldPassword: 'Parolă veche SRB',
    newPassword: 'Parolă nouă SRB',
    confirmNewPassword: 'Confirmare parolă nouă SRB',
    placeholder: 'Type here SRB',
    save: 'SALVARE PAROLĂ SRB',
    confirm: {
      title: 'Parola a fost schimbată cu succes SRB',
      description:
        'Use your new password when you log in to Wonderland Essentials platform. SRB',
      loginBTN: 'AUTENTIFICARE SRB',
    },
    cancel: 'Cancel',
  },
  loginPage: {
    emailLabel: 'Email address SRB',
    emailInput: 'Your email address SRB',
    passwordLabel: 'Password SRB',
    passwordInput: 'Your password SRB',
    forgotPassword: 'Forgot password? SRB',
    text: 'Become a member SRB',
  },

  // forgot password page
  forgotPasswordPage: {
    title: 'Forgot password SRB',
    subtitle:
      'Introduceți adresa de e-mail asociată contului dvs. și vom trimite un link pentru a vă reseta parola. SRB',
    emailLabel: 'Email address SRB',
    emailInput: 'Your email address SRB',
    buttons: {
      continue: 'Continuare SRB',
      login: 'Reset password SRB',
      cancel: 'Anulare SRB',
    },
    linkSentTitle: 'Email sent SRB',
    linkSent:
      'We’ve sent an email to the provided email address with further instructions. You can close this window. SRB',
  },

  // products
  productsPage: {
    pageTitle: 'Produse SRB',
    favoriteProducts: 'Favorite products SRB',
    search: 'Search SRB',
    showing: {
      pre: 'Se afișează SRB',
      post: 'Produse SRB',
      plural: 's SRB',
      out: 'out of SRB',
      none: 'Nici un produs SRB',
    },
    categories: {
      singleOils: 'Single Oils SRB',
      blends: 'Blends SRB',
      supplements: 'Supplements SRB',
      personalCare: 'Personal Care SRB',
      household: 'Household SRB',
      oilKits: 'Kits SRB',
      diffusers: 'Diffusers SRB',
      accesories: 'Accesories SRB',
    },
    extractionMethod: 'Metoda de extracție SRB',
    description: 'Descriere SRB',
    ingredients: 'Ingrediente SRB',
    benefits: 'Beneficii SRB',
    cautions: 'Precauții SRB',
    translatedBy: 'Mulțumiri SRB',
    toastMessage: {
      addProduct: 'Product added to Favorites',
      removeProduct: 'Product removed from Favorites',
    },
  },
  // single product page
  productPage: {
    breadcrumbs: {
      all: 'Toate produsele SRB',
    },
    editProduct: 'Editați produsul SRB',
    extractionMethod: 'Metoda de extracție SRB',
    description: 'Descriere SRB',
    ingredients: 'Ingrediente SRB',
    benefits: 'Beneficii SRB',
    cautions: 'Precauții SRB',
    directionsOfUse: 'Directions of use SRB',
    translated: 'Adapted and Translated By SRB',
    sources: 'Sources SRB',
    disclaimer: 'Disclaimer SRB',
    disclaimerInfo:
      'This information is a compilation of suggestions made by those who have used essential oils and do not intend to substitute the advice of a medical doctor. It is an informative document from personal experience and should be treated as such. The recommended products and methods are not intended to diagnose, treat, cure or prevent any disease, nor are they intended to replace appropriate medical care SRB',
    extraction: {
      none: 'Niciuna',
      notApplicable: 'Nu se aplica',
      steamDistillationOfFlowers: 'Steam distillation of flowers',
      steamDistillationOfLeaves: 'Steam distillation of leaves',
      steamDistillationOfFruit: 'Steam distillation of fruit',
      steamDistillationOfRoots: 'Steam distillation of roots',
      steamDistillationOfRhizomes: 'Steam distillation of rhizomes',
      steamDistillationOfWood: 'Steam distillation of wood',
      steamDistillationOfResin: 'Steam distillation of resin',
      steamDistillationOfSap: 'Steam distillation of sap',
      steamDistillationOfSeeds: 'Steam distillation of seeds',
      steamDistillationOfBark: 'Steam distillation of bark',
      steamDistillationOfLeavesAndFlowers:
        'Steam distillation of leaves and flowers',
      steamDistillationOfNeedlesAndTwigs:
        'Steam distillation of needles and twigs',
      steamDistillationOfFruitsLeavesAndSeeds:
        'Steam distillation of fruits, leaves and seeds',
      steamDistillationOfLeavesAndYoungBranches:
        'Steam distillation of leaves and young branches',
      steamDistillationOfLeavesAndPlantTips:
        'Steam distillation of leaves and plant tips',
      steamDistillationOfLeavesAndStems:
        'Steam distillation of leaves and stems',
      steamDistillationOfTheFloweringPartOfThePlant:
        'Steam distillation of the flowering part of the plant',
      steamDistillationOfFlowerBudsStemsAndLeaves:
        'Steam distillation of flower buds, stems and leaves-',
      steamDistillationOfLeavesSeedsSapAndBark:
        'Steam distillation of leaves, seeds, sap and bark',
      steamDistillationOfBudsAndStems: 'Steam distillation of buds and stems',
      steamDistillationOfFlowersBudsAndLeaves:
        'Steam distillation of flowers, buds and leaves',
      coldPressingOfFruitPeel: 'Cold pressing of fruit peel',
      hydroDistillationFromRoots: 'Hidro distilare din radacini',
      absoluteFromFlowers: 'Absolute from flowers',
      absoluteFromBeansOrPods: 'Absolute from beans/pods',
    },
  },
  // my landing page
  landingPage: {
    footer: {
      text: 'Bine ai venit acasă. Bine ai venit în Wonderland Essentials. O lume plină de uleiuri esențiale doTERRA și magie - magia devenirii.  SRB',
      doTERRAWebsite: 'Website oficial doTERRA SRB',
      cookie: 'Politica Cookie SRB',
      privacy: 'Politica de confidențialitate SRB',
      terms: 'Terms and Conditions SRB',
      poweredBy: 'Powered by', //new
      content: '©2021 Wonderland Essentials. Toate drepturile rezervate. SRB',
      disclaimer: 'Disclaimer SRB',
    },
  },
  // events
  eventsPage: {
    pageTitle: 'Evenimente SRB',
  },
  // single event page
  eventPage: {
    breadcrumbs: {
      all: 'Toate Evenimentele SRB',
    },
    editEvent: 'Edit event SRB',
  },

  //
  // protocols
  protocolsPage: {
    pageTitle: 'Protocoale SRB',
    favoriteText: 'Arată doar favorite SRB',
    favoriteProtocols: 'Favorite protocols SRB',
    search: 'Search SRB',
    showing: {
      pre: 'Arată SRB',
      mid: 'of SRB',
      post: 'Protocol SRB',
      plural: 's SRB',
      none: 'Fără Protocoale SRB',
    },
    categories: {
      all: 'All',
      physical: 'Physical',
      emotional: 'Emotional',
    },
    description: 'Descriere SRB',
    ingredients: 'Ingrediente SRB',
    benefits: 'Beneficii SRB',
    cautions: 'Precauții SRB',
    toastMessage: {
      addProtocol: 'Protocol added to Favorites',
      removeProtocol: 'Protocol removed from Favorites',
    },
  },

  // single protocol page
  protocolPage: {
    breadcrumbs: {
      all: 'Toate Protocoalele SRB',
    },
    editProtocol: 'Editați protocolul SRB',
    physical: 'Fizic SRB',
    emotional: 'Emoţional SRB',
    cautions: 'Cautions SRB',
    info: '  If you have any questions about this protocol, please contact your consultant. SRB',
    disclaimer: 'Disclaimer SRB',
    applicationZones: 'Application zones SRB',
    topicalRecommendation: 'Recomandare Topică SRB',
    aromaticalRecommendation: 'Recomandare Aromatică SRB',
    internalRecommendation: 'Recomandare Internă SRB',
    additionalRecommendations: 'RECOMANDĂRI SUPLIMENTARE SRB',
    protocolDisclamer:
      '*Aceste informații reprezintă o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intentioneaza a subtitui sfatul unui medic profesionist. Este un document cu caracter informativ din experiențe personale și trebuiesc tratate ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină orice boală și nici nu intenționează să înlocuiască ajutorul medical adecvat. SRB',
    physicalTopicalRecommendation: 'Recomandare Fizică Topică SRB',
    physicalAromaticalRecommendation: 'Recomandare Fizică Aromatică SRB',
    physicalInternalRecommendation: 'Recomandare Fizică Internă SRB',
    physicalAdditionalRecommendation: 'Recomandări Fizice Suplimentare SRB',
    addPhysicalProtocol: 'Adăugați Protocol Fizic SRB',
    emotionalTopicalRecommendation: 'Recomandare Emoțională Topică SRB',
    emotionalAromaticalRecommendation: 'Recomandare Emoțională Aromatică SRB',
    emotionalInternalRecommendation: 'Recomandare Emoțională Internă SRB',
    emotionalAdditionalRecommendations:
      'Recomandări Emoționale Suplimentare SRB',
  },
  // 404 not found page
  notFoundPage: {
    notFound: 'Pagina nu a fost găsită SRB',
    title: 'Hopa! Se pare că s-a spart ceva. SRB',
    content: 'Sperăm că nu este o sticlă de ulei esențial... SRB',
    button: 'Înapoi acasă SRB',
  },
  // contact page
  contactPage: {
    title: 'Hai să ne auzim SRB',
    text: 'Completează formularul. Este super ușor. SRB',
    content: 'Ne puteți contacta și prin email la  SRB',
    website: 'support@wonderlandessentials.com SRB',
    contactEmail: 'mailto:support@wonderlandessentials.com SRB',
    button: 'Trimite Mesaj SRB',
    typeEmail: 'Adresa de email SRB',
    textAreaLabel: 'Spune-ne pe scurt cum te putem ajuta SRB',
    messageSent: 'Mesajul tău a fost trimis. SRB',
  },
  // request error messages
  errors: {
    auth: {
      login: {
        notFound: 'Utilizatorul nu există . SRB',
        wrongPass: 'Parola incorectă. SRB',
        generic: 'A apărut o eroare. Incearcă din nou mai tarziu. SRB',
      },
    },
    products: {
      badRequest: 'Bad request. SRB',
      notFound: 'Produsul nu a fost găsit. SRB',
    },
    protocols: {
      badRequest: 'Bad request. SRB',
      notFound: 'Protocolul nu a fost găsit. SRB',
    },
    events: {
      badRequest: 'Bad request. SRB',
      notFound: 'Evenimentul nu a fost găsit. SRB',
    },
    users: {
      badRequest: 'Bad request. SRB',
      notFound: 'Utilizatorul nu a fost găsit. SRB',
    },
  },
  //consultations page
  consultations: {
    title: 'Consultations SRB',
    subTitle: '15-minute FREE Consultation with Alice Vacian SRB',
    info: 'In the free consultation sessions conducted in English, expect a safe and intimate space to share your concerns with a tailored essential oil blend for support from Alice. These sessions offer a brief opportunity to gain insight and experience the benefits of essential oils. SRB',
    important:
      'IMPORTANT! Before booking a consultation, make sure of the following:  SRB',
    list: {
      item1: 'You are in a quiet environment SRB',
      item2: 'You have something to take notes on SRB',
      item3: 'You have one question, so ask the most pressing one SRB',
      item4: 'You have ZOOM installed, and your mic and camera are working SRB',
      item5: {
        firstSpan:
          "If you can't make it to our meeting, please let us know in advance at SRB ",
        secondSpan: 'alice@wonderlandessentials.com',
      },
      item6:
        'Limit consultations to one per month to ensure equal enjoyment due to the high demand SRB',
    },
    button: 'Continue to booking SRB',
  },
  dashboard: {
    greetings: 'Hi SRB',
    title: 'Dashboard SRB', //new
    blendOfTheMonth: 'Blend of the month SRB', //new
    viewBlends: 'View all blends SRB',
    noBlends: 'No blends SRB', //new
    monthlyOffer: 'Monthly offers SRB',
    upcomingEvent: 'Upcoming Events SRB',
    latestArticles: 'Latest articles SRB',
  },

  //blend of the month
  blendDetailPage: {
    title: 'Blend of the month SRB',
    allBlend: ' All Blend of the month SRB',
    frequency: 'Frequency SRB',
    duration: 'Duration SRB',
    cautions: 'Cautions SRB',
    applicationZones: 'Application zones SRB',
    information: 'Important Information SRB',
    informationContent:
      'PLEASE DO NOT SHARE THIS BLEND OUTSIDE OF OUR COMMUNITY. These blends are specifically designed for you who already have a spiritual practice. I hold space for those on our team who use these blends. Emotional difficulties could be challenging to manage if someone applies without proper support. SRB',
    disclaimer: 'Disclaimer SRB',
    disclaimerContent:
      ' This information is a compilation of suggestions made by those who have used essential oils and do not intend to substitute the advice of a medical doctor. It is an informative document from personal experience and should be treated as such. The recommended products and methods are not intended to diagnose, treat, cure or prevent any disease, nor are they intended to replace appropriate medical care. SRB',
    edit: 'Edit blend SRB',
  },

  //eduCarePage
  eduCarePage: {
    pageTitle: 'eduCare SRB',
    allArticles: 'All articles SRB',
    search: 'Search eduCare SRB',
    title1: 'Discover the world of  SRB',
    title2: 'doTERRA essential oils SRB',
    description:
      'Welcome to our "eduCare" section, where we provide educational resources on doTERRA essential oils. Our articles cover the basics of essential oils, including their uses, benefits, and safety considerations. We also provide tips on using essential oils for specific health concerns and incorporating them into your daily routine. SRB',
    subTitle: 'In-depth articles for curious minds SRB',
    startBasics: 'Start with the Basics',
    editArticle: 'Edit this article  SRB',
    readTime: 'min read SRB',
  },

  // application zones
  applicationZones: {
    solesOfFeet: 'Soles of Feet SRB',
    neck: 'Neck SRB',
    forhead: 'Forehead SRB',
    armpits: 'Armpits SRB',
    solarPlexus: 'Solar Plexus SRB',
    heart: 'Heart SRB',
    sacrum: 'Sacrum SRB',
    lombarRegion: 'Lombar Region SRB',
    sinuses: 'Sinuses SRB',
    underTheNose: 'Under the Nose SRB',
    chest: 'Chest SRB',
    backOfNeck: 'Back of Neck SRB',
    spine: 'Spine SRB',
    diaphragm: 'Diaphragm SRB',
    back: 'Back SRB',
    topOfHead: 'Top of Head SRB',
    aroundBellyButton: 'Around Belly Button SRB',
    lowerAbdomen: 'Lower Abdomen SRB',
    insideWristCreases: 'Inside Wrist Creases SRB',
    temples: 'Temples SRB',
    baseOfTheSkull: 'Base of Skull SRB',
    behindTheEars: 'Behind the Ears SRB',
    alongTheArms: 'Along the Arms SRB',
    abdomen: 'Abdomen SRB',
    shoulders: 'Shoulders SRB',
    kidneys: 'Kidneys SRB',
    midlineOfTheBelly: 'Midline of the Belly SRB',
    insideElbowCreases: 'Inside Elbow Creases SRB',
    outerLineOfTheJaw: 'Outer Line of the Jaw SRB',
    sternBone: 'Sternum SRB',
    outsideWristCreases: 'Outside Wrist Creases SRB',
    innerThighArea: 'Inner Thigh Area SRB',
    theBaseOfTheNeck: 'The Base of the Neck SRB',
    mastoid: 'Mastoid SRB',
    feet: 'Feet SRB',
    behindTheKnees: 'Behind the Knees SRB',
    tailBone: 'Tail Bone SRB',
    liverArea: 'Liver Area SRB',
    adrenals: 'Adrenals SRB',
    thirdEye: 'Third eye SRB',
    noseWings: 'Nose Wings SRB',
    thymus: 'Thymus SRB',
    ankles: 'Ankles SRB',
    bitToes: 'Big Toes SRB',
  },
};
