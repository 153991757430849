import { stringsRO } from './ro';
import { stringsEN } from './en';
import { stringsRS } from './rs';

export const resources = {
  en: {
    translation: stringsEN,
  },
  ro: {
    translation: stringsRO,
  },
  rs: {
    translation: stringsRS,
  },
};
