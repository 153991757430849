export const stringsRO = {
  // generics
  generic: {
    cancel: 'Anulare',
    forms: {
      firstName: 'Prenume',
      lastName: 'Nume',
      language: 'Limba',
      phone: 'Număr de telefon',
      country: 'Țara',
      doterraId: 'doTERRA ID',
      email: 'Adresa de email',
      password: 'Parola',
      typeHere: 'Scrie aici',
      search: 'Caută',
    },
  },
  // avatar dropdown header
  hello: 'Salut',
  myAccount: 'Contul meu',
  userManagement: 'Gestionarea utilizatorilor',
  contentManagement: 'Managementul conținutului ',
  newProduct: 'Produs nou',
  newProtocol: 'Protocol nou',
  newEvent: 'Eveniment nou',
  newEduCare: 'Un nou articol eduCare',
  newBlend: 'Noul amestec al lunii ',
  contact: 'Contact',
  logout: 'Deconectați-vă',

  // navigation
  products: 'Produse',
  protocols: 'Protocoale',
  resources: 'Resurse',
  bookings: 'Programări',
  eduCare: ' eduCare',
  consultation: 'Consultații',
  events: 'Evenimente',
  search: 'Căutare',
  login: 'Autentificare',
  changeLanguage: 'Schimbați limba',
  // login page
  loginTitle: 'Autentificare membru',
  loginSubtitle: 'O lume plină de magie și uleiuri esențiale doTERRA',
  typeEmail: 'Introduceți adresa de email',
  typePassword: 'Introduceți parola',
  forgotPassword: 'Ați uitat parola?',
  registerLink: 'Nu ai cont?',
  buttonLogin: 'Autentificare',

  // my account page
  myAccountPage: {
    doterraId: 'ID-ul doTERRA al utilizatorului',
    changePassword: 'Schimbați parola',
    update: 'Actualizați informațiile',
    erros: {
      firstNameRequired: 'Primul nume nu poate fi gol',
      firstMin: 'Prenumele trebuie să fie de cel puțin 2 caracter',
      firstMax: 'Prenumele trebuie să conțină cel mult 20 de caractere ',
      lastNameRequired: 'Numele de familie nu poate fi gol',
      lastMin: 'Numele de familie trebuie să aibă cel puțin 2 caractere ',
      lastMax: 'Numele de familie trebuie să conțină cel mult 20 de caractere',
      countryRequired: 'Țara nu poate fi goală ',
      phoneRequired: 'Telefonul nu poate fi gol ',
    },
  },
  //userManagement page
  usersManagement: {
    addUser: '+ Adăugați un nou',
    all: 'Toate',
    search: 'Caută',
    showing: {
      pre: 'Se afișează',
      user: 'utilizator',
      mid: 'din',
      plural: 'de utilizatori',
      none: 'Nici un utilizator',
    },
    pagination: {
      show: 'Arata',
      page: 'Pag',
      of: 'din',
    },
    table: {
      name: 'Nume',
      country: 'Tara',
      submissionDate: 'Data inregistrarii',
      assignedEnroller: 'Inrolator',
      doTERRAID: 'ID doTERRA',
      accountType: 'Tip cont',
      status: 'Status',
    },
  },

  // initial password setup page
  initialPasswordPage: {
    title: 'Bine ați venit pe Wonderland Essentials',
    subtitle: 'Haideți să creăm parola contului dumneavoastră.',
    yourPassword: 'Parolă dumneavoastră',
    placeholder: 'Tastați aici',
    confirmPassword: 'Confirmare parolă',
    terms: {
      accept: 'Sunt de acord cu ',
      terms: 'termenii și condițiile',
    },
  },
  // change password page
  chooseNewPasswordPage: {
    title: 'Alegeți o parolă nouă',
    passwordRequirements:
      'Parolele trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin 1 cifră, 1 literă mare, 1 literă mică și 1 caracter special.',
    oldPassword: 'Parolă veche',
    newPassword: 'Parolă nouă',
    confirmNewPassword: 'Confirmare parolă nouă',
    placeholder: 'Tastați aici',
    save: 'SALVARE PAROLĂ',
    confirm: {
      title: 'Parola a fost schimbată cu succes',
      description:
        'Utilizați noua parolă atunci când vă conectați la platforma Wonderland Essentials.',
      loginBTN: 'AUTENTIFICARE',
    },
    cancel: 'Cancel',
  },
  loginPage: {
    emailLabel: 'Adresă de email',
    emailInput: 'Introduceți adresa de email',
    passwordLabel: 'Parolă',
    passwordInput: 'Introduceți parola',
    forgotPassword: 'Ați uitat parola?',
    text: 'Devino membru',
  },

  // forgot password page
  forgotPasswordPage: {
    title: 'Am uitat parola',
    subtitle:
      'Introduceți adresa de e-mail asociată contului dvs. și vom trimite un link pentru a vă reseta parola.',
    emailLabel: 'Adresă email',
    emailInput: 'Introdu adresă email',
    buttons: {
      continue: 'Continuă',
      login: 'Resetare parolă',
      cancel: 'Anulare',
    },
    linkSentTitle: 'Email trimis',
    linkSent:
      'Am trimis un e-mail la adresa de e-mail furnizată cu instrucțiuni suplimentare.',
  },

  // products
  productsPage: {
    pageTitle: 'Produse',
    favoriteProducts: 'Produse preferate',
    search: 'Caută',
    showing: {
      pre: 'Se afișează',
      post: 'Produs',
      plural: 'Produse',
      out: 'din',
      none: 'Nici un produs',
    },
    categories: {
      singleOils: 'Uleiuri',
      blends: 'Amestecuri',
      supplements: 'Suplimente',
      personalCare: 'Îngrijire personală',
      household: 'Gospodărie',
      oilKits: 'Kituri',
      diffusers: 'Difuzoare',
      accesories: 'Accesorii',
    },
    extractionMethod: 'Metoda de extracție',
    description: 'Descriere',
    ingredients: 'Ingrediente',
    benefits: 'Beneficii',
    cautions: 'Precauții',
    translatedBy: 'Mulțumiri',
    toastMessage: {
      addProduct: 'Produs adăugat la favorite',
      removeProduct: 'Produs eliminat din favorite ',
    },
  },
  // single product page
  productPage: {
    breadcrumbs: {
      all: 'Toate produsele',
    },
    editProduct: 'Editați produsul',
    extractionMethod: 'Metoda de extracție',
    description: 'Descriere',
    ingredients: 'Ingrediente',
    benefits: 'Beneficii',
    cautions: 'Precauții',
    directionsOfUse: 'Mod de utilizare',
    translated: 'Adaptat și tradus de',
    sources: 'Surse',
    disclaimer: 'Disclaimer',
    disclaimerInfo:
      'Aceste informații sunt o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intenționează să înlocuiască sfatul unui medic. Este un document informativ din experiența personală și trebuie tratat ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină nicio boală și nici nu au scopul de a înlocui îngrijirea medicală adecvată',
    extraction: {
      none: 'Niciuna',
      notApplicable: 'Nu se aplică',
      steamDistillationOfFlowers: 'Distilare cu aburi a florilor',
      steamDistillationOfLeaves: 'Distilare cu aburi a frunzelor',
      steamDistillationOfFruit: 'Distilare cu aburi a fructelor',
      steamDistillationOfRoots: 'Distilare cu aburi a rădăcinilor',
      steamDistillationOfRhizomes: 'Distilare cu aburi a rizomilor',
      steamDistillationOfWood: 'Distilare cu aburi a lemnului',
      steamDistillationOfResin: 'Distilare cu aburi a rășinii',
      steamDistillationOfSap: 'Distilare cu aburi a sevei',
      steamDistillationOfSeeds: 'Distilare cu aburi a semințelor',
      steamDistillationOfBark: 'Distilare cu aburi a scoarței',
      steamDistillationOfLeavesAndFlowers:
        'Distilare cu aburi a frunzelor și florilor',
      steamDistillationOfNeedlesAndTwigs:
        'Distilare cu aburi a acelor și lăstarilor tineri',
      steamDistillationOfFruitsLeavesAndSeeds:
        'Distilare cu aburi a fructelor, frunzelor și semințelor',
      steamDistillationOfLeavesAndYoungBranches:
        'Distilare cu aburi a frunzelor și ramurilor tinere',
      steamDistillationOfLeavesAndPlantTips:
        'Distilare cu aburi a frunzelor și vârfurilor plantei',
      steamDistillationOfLeavesAndStems:
        'Distilare cu aburi a frunzelor și crengilor',
      steamDistillationOfTheFloweringPartOfThePlant:
        'Distilare cu aburi a părții înflorite a plantei',
      steamDistillationOfFlowerBudsStemsAndLeaves:
        'Distilare cu aburi a mugurilor și lăstarilor tineri',
      steamDistillationOfLeavesSeedsSapAndBark:
        'Distilare cu aburi a frunzelor, semințelor, sevei și scoarței',
      steamDistillationOfBudsAndStems:
        'Distilare cu aburi a mugurilor de flori, lăstarilor și frunzelor',
      steamDistillationOfFlowersBudsAndLeaves:
        'Distilare cu aburi a florilor, mugurilor și frunzelor',
      coldPressingOfFruitPeel: 'Presare la rece a coajei',
      hydroDistillationFromRoots: 'Hidro distilare din rădăcini',
      absoluteFromFlowers: 'Absolut din flori',
      absoluteFromBeansOrPods: 'Absolut din boabe/pastai',
    },
  },
  // my landing page
  landingPage: {
    footer: {
      text: 'Bine ai venit acasă. Bine ai venit în Wonderland Essentials. O lume plină de uleiuri esențiale doTERRA și magie - magia devenirii. ',
      doTERRAWebsite: 'Website oficial doTERRA',
      cookie: 'Politica Cookie',
      privacy: 'Politica de confidențialitate',
      terms: 'Termenii si Condițiile',
      poweredBy: 'Powered by',
      content: 'Wonderland Essentials. Toate drepturile rezervate.',
      disclaimer: 'Disclaimer',
    },
  },
  // events
  eventsPage: {
    pageTitle: 'Evenimente',
  },
  // single event page
  eventPage: {
    breadcrumbs: {
      all: 'Toate Evenimentele',
    },
    editEvent: 'Editați evenimentul',
  },

  // protocols
  protocolsPage: {
    pageTitle: 'Protocoale',
    favoriteText: 'Arată doar favorite',
    favoriteProtocols: 'Protocoale preferate',
    search: 'Caută',
    showing: {
      pre: 'Arată',
      mid: 'de',
      post: 'Protocol',
      plural: 'Protocoale',
      none: 'Nici un protocol',
    },
    categories: {
      all: 'Toate',
      physical: 'Fizic',
      emotional: 'Emotional',
    },
    description: 'Descriere',
    ingredients: 'Ingrediente',
    benefits: 'Beneficii',
    cautions: 'Precauții',
    toastMessage: {
      addProtocol: 'Protocol added to Favorites',
      removeProtocol: 'Protocol removed from Favorites',
    },
  },

  // single protocol page
  protocolPage: {
    breadcrumbs: {
      all: 'Toate Protocoalele',
    },
    editProtocol: 'Editați protocolul',
    physical: 'Fizic',
    emotional: 'Emoţional',
    cautions: 'Precauții ',
    info: 'Dacă aveți întrebări cu privire la acest protocol, vă rugăm să contactați consultantul dvs.',
    disclaimer: 'Disclaimer',
    applicationZones: 'Zone de aplicare',
    topicalRecommendation: 'Recomandare Topică',
    aromaticalRecommendation: 'Recomandare Aromatică',
    internalRecommendation: 'Recomandare Internă',
    additionalRecommendations: 'RECOMANDĂRI SUPLIMENTARE',
    protocolDisclamer:
      '*Aceste informații reprezintă o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intentioneaza a substitui sfatul unui medic profesionist. Este un document cu caracter informativ sintetizat din experiențe personale și trebuie tratate ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină vreo boală și nici nu intenționează să înlocuiască ajutorul medical adecvat.',
    physicalTopicalRecommendation: 'Recomandare Fizică Topică',
    physicalAromaticalRecommendation: 'Recomandare Fizică Aromatică',
    physicalInternalRecommendation: 'Recomandare Fizică Internă',
    physicalAdditionalRecommendation: 'Recomandări Fizice Suplimentare',
    addPhysicalProtocol: 'Adăugați Protocol Fizic',
    emotionalTopicalRecommendation: 'Recomandare Emoțională Topică',
    emotionalAromaticalRecommendation: 'Recomandare Emoțională Aromatică',
    emotionalInternalRecommendation: 'Recomandare Emoțională Internă',
    emotionalAdditionalRecommendations: 'Recomandări Emoționale Suplimentare',
  },
  // 404 not found page
  notFoundPage: {
    notFound: 'Pagina nu a fost găsită',
    title:
      'Se pare că pagina pe care o căutați a fost mutată sau nu mai este disponibilă.',
    content: 'Sperăm că nu este o sticlă de ulei esențial...',
    button: 'Înapoi acasă',
  },
  // contact page
  contactPage: {
    title: 'Hai să ne auzim',
    text: 'Completează formularul. Este super ușor.',
    content: 'Ne puteți contacta și prin email la ',
    website: 'support@wonderlandessentials.com',
    contactEmail: 'mailto:support@wonderlandessentials.com',
    button: 'Trimite Mesaj',
    typeEmail: 'Adresa de email',
    textAreaLabel: 'Spune-ne pe scurt cum te putem ajuta',
    messageSent: 'Mesajul tău a fost trimis.',
  },
  // request error messages
  errors: {
    auth: {
      login: {
        notFound: 'Utilizatorul nu există ',
        wrongPass: 'Parola incorectă.',
        generic: 'A apărut o eroare. Incearcă din nou mai tarziu.',
      },
    },
    products: {
      badRequest: 'Bad request.',
      notFound: 'Produsul nu a fost găsit.',
    },
    protocols: {
      badRequest: 'Bad request.',
      notFound: 'Protocolul nu a fost găsit.',
    },
    events: {
      badRequest: 'Bad request.',
      notFound: 'Evenimentul nu a fost găsit.',
    },
    users: {
      badRequest: 'Bad request.',
      notFound: 'Utilizatorul nu a fost găsit.',
    },
  },
  //consultations page
  consultations: {
    title: 'Consultații',
    subTitle: 'Consultație GRATUITĂ de 15 minute cu Alice Vacian',
    info: 'In consultatia gratuita de 15 minute desfasurata in limba romana iti ofer un spatiu sigur si intim in care poti impartasi povestile tale si un amestec personalizat, special creat sa iti vina in sprijin. Aceste intalniri iti daruiesc oportunitatea de a descoperi si de a te bucura de beneficiile uleiurilor esentiale.',
    important: 'IMPORTANT! Inainte de a programa o consultatie, asigura-te ca:',
    list: {
      item1: 'Esti intr-un spatiu fara zgomot',
      item2: 'Ai la indemana foaie si pix pentru notite',
      item3:
        'Ai o singura intrebare, asigura-te ca e cea mai importanta pentru tine acum',
      item4: 'Ai aplicatia ZOOM instalata, microfonul si camera functionale',
      item5: {
        firstSpan:
          'Daca nu ajungi la intalnire, te rog sa ma anunti din timp la ',
        secondSpan: 'alice@wonderlandessentials.com',
      },
      item6:
        'Limiteaza-te la o intalnire pe luna, astfel ne bucuram toti in egala masura',
    },
    button: 'Continuați cu rezervarea',
  },
  dashboard: {
    greetings: 'Salut',
    title: 'Dashboard',
    blendOfTheMonth: 'Amestecul lunii',
    viewBlends: 'Vezi toate amestecurile ',
    noBlends: 'Nici un amestec',
    monthlyOffer: 'Ofertele lunii',
    upcomingEvent: 'Evenimente viitoare',
    latestArticles: 'Ultimele articole',
  },

  //blend of the month
  blendDetailPage: {
    title: 'Blend of the month',
    allBlend: 'Toate Amestec al lunii ',
    frequency: 'Frecvență',
    duration: 'Durata',
    cautions: 'Precauții',
    applicationZones: 'Zone de aplicare',
    information: 'Informații importante',
    informationContent:
      'VĂ RUGĂM SĂ NU DISTRIBUIȚI ACEST AMESTEC ÎN AFARA COMUNITĂȚII NOASTRE. Aceste amestecuri sunt special concepute pentru cei care au deja o practică spirituală. Păstrez spațiu pentru cei din echipa noastră care folosesc aceste amestecuri. Dificultățile emoționale ar putea fi dificil de gestionat dacă cineva aplică fără un sprijin adecvat.',
    disclaimer: 'Disclaimer',
    disclaimerContent:
      'Aceste informații sunt o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intenționează să înlocuiască sfatul unui medic. Este un document informativ din experiența personală și trebuie tratat ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină nicio boală și nici nu au scopul de a înlocui îngrijirea medicală adecvată',
    edit: 'Editare amestec',
  },

  // eduCare page
  eduCarePage: {
    pageTitle: 'eduCare',
    allArticles: 'Toate articolele',
    search: 'Caută în eduCare',
    title1: 'Descoperiți lumea de ',
    title2: 'uleiuri esențiale doTERRA',
    description:
      'Bine ați venit în secțiunea noastră "eduCare", unde vă oferim resurse educaționale despre uleiurile esențiale doTERRA. Articolele noastre acoperă elementele de bază ale uleiurilor esențiale, inclusiv utilizările, beneficiile și considerentele de siguranță ale acestora. De asemenea, oferim sfaturi privind utilizarea uleiurilor esențiale pentru probleme de sănătate specifice și încorporarea acestora în rutina zilnică.',
    subTitle: 'Articole aprofundate pentru mințile curioase',
    startBasics: 'Începeți cu elementele de bază',
    editTitle: 'Modifică articol',
    readTime: 'min citește ',
  },

  // application zones
  applicationZones: {
    solesOfFeet: 'Talpile picioarelor',
    neck: 'Gat',
    forhead: 'Frunte',
    armpits: 'Axila',
    solarPlexus: 'Plex solar',
    heart: 'Inima',
    sacrum: 'Os sacru',
    lombarRegion: 'Zona lombara',
    sinuses: 'Sinusuri',
    underTheNose: 'Sub nas',
    chest: 'Piept',
    backOfNeck: 'Ceafa',
    spine: 'Coloana',
    diaphragm: 'Diafragma',
    back: 'Spate',
    topOfHead: 'Crestetul capului',
    aroundBellyButton: 'In jurul buricului',
    lowerAbdomen: 'Abdomenul inferior',
    insideWristCreases: 'Incheieturi interioare ale mainilor',
    temples: 'Tample',
    baseOfTheSkull: 'Baza craniului',
    behindTheEars: 'In spatele urechilor',
    alongTheArms: 'De-a lungul bratelor',
    abdomen: 'Abdomen',
    shoulders: 'Umeri',
    kidneys: 'Rinichi',
    midlineOfTheBelly: 'Mijlocul abdomenului',
    insideElbowCreases: 'Incheieturi interioare ale cotului',
    outerLineOfTheJaw: 'Linia maxilarului',
    sternBone: 'Stern',
    outsideWristCreases: 'Incheieturi exterioare ale cotului',
    innerThighArea: 'Zona interioară a coapsei',
    theBaseOfTheNeck: 'Baza gatului',
    mastoid: 'Mastoid',
    feet: 'Picioare',
    behindTheKnees: 'In spatele genunchilor',
    tailBone: 'Coccis',
    liverArea: 'Zona ficatului',
    adrenals: 'Glande suprarenale',
    thirdEye: 'Al treilea ochi',
    noseWings: 'Aripile nasului',
    thymus: 'Timus',
    ankles: 'Glezne',
    bitToes: 'Degetele mari de la picioare',
  },
};
