export const stringsEN = {
  // generics
  generic: {
    cancel: 'Cancel',
    forms: {
      firstName: 'First name',
      lastName: 'Last name',
      language: 'Language',
      phone: 'Phone number',
      country: 'Country',
      doterraId: 'doTERRA ID',
      email: 'Email address',
      password: 'Password',
      typeHere: 'Type here',
      search: 'Search',
    },
  },
  // avatar dropdown header
  hello: 'Hello',
  myAccount: 'Account Settings',
  userManagement: 'User management',
  contentManagement: 'Content Management',
  newProduct: 'New Product',
  newProtocol: 'New Protocol',
  newEvent: 'New Event',
  newEduCare: 'New eduCare Article',
  newBlend: 'New Blend of the Month',
  contact: 'Contact',
  logout: 'Logout',

  // navigation
  products: 'Products',
  protocols: 'Protocols',
  resources: 'Resources',
  bookings: 'Bookings',
  eduCare: 'eduCare',
  consultation: 'Consultations',
  events: 'Events',
  search: 'Search',
  login: 'Login',
  changeLanguage: 'Change language ',
  // login page
  loginTitle: 'Login',
  loginSubtitle:
    'Only members can log in and use the Wonderland Essentials platform.',
  typeEmail: 'Type in your email',
  typePassword: 'Type in your password',
  forgotPassword: 'Forgot password?',
  registerLink: "Don't have an account?",
  buttonLogin: 'Login',
  // my account page
  myAccountPage: {
    doterraId: 'User doTERRA ID',
    changePassword: 'Change password',
    update: 'Update information',
    erros: {
      firstNameRequired: "First name can't be empty",
      firstMin: 'First name must be at least 2 characters',
      firstMax: 'First name must be at most 20 characters',
      lastNameRequired: "Last name can't be empty",
      lastMin: 'Last name must be at least 2 characters',
      lastMax: 'Last name must be at most 20 characters',
      countryRequired: "Country can't be empty",
      phoneRequired: "Phone can't be empty",
    },
  },
  //userManagement page
  usersManagement: {
    addUser: '+ Add new',
    all: 'All',
    search: 'Search',
    showing: {
      pre: 'Showing',
      user: 'user',
      mid: 'out of',
      plural: 'users',
      none: 'No users',
    },
    pagination: {
      show: 'Show',
      page: 'Page',
      of: 'of',
    },
    table: {
      name: 'Full name',
      country: 'Country',
      submissionDate: 'Form submission date',
      assignedEnroller: 'Assigned enroller',
      doTERRAID: 'doTERRA ID',
      accountType: 'Account type',
      status: 'Status',
    },
  },

  // initial password setup page
  initialPasswordPage: {
    title: 'Welcome to Wonderland Essentials',
    subtitle: "Let's create your account password.",
    yourPassword: 'Type Password',
    placeholder: 'Type here',
    confirmPassword: 'Re-type Password',
    terms: {
      accept: 'I accept the ',
      terms: 'Terms and Conditions',
    },
  },
  // change password page
  chooseNewPasswordPage: {
    title: 'Choose new password',
    passwordRequirements:
      'Passwords must be at least 8 characters long and it must contain at least 1 digit, 1 uppercase letter, 1 lowercase letter and 1 special character.',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    placeholder: 'Type here',
    save: 'Continue',
    confirm: {
      title: 'The password was changed successfully!',
      description:
        'Use your new password when you log in to Wonderland Essentials platform.',
      loginBTN: 'Continue',
    },
    cancel: 'Cancel',
  },
  loginPage: {
    emailLabel: 'Email address',
    emailInput: 'Type in your email',
    passwordLabel: 'Password',
    passwordInput: 'Type in your password',
    forgotPassword: 'Forgot password?',
    text: 'I want to become a member',
  },

  // forgot password page
  forgotPasswordPage: {
    title: 'Forgot password',
    subtitle:
      "Enter the email associated with your account, and we'll send you a link to reset your password.",
    emailLabel: 'Email address',
    emailInput: 'Your email address',
    buttons: {
      continue: 'Reset password',
      login: 'Reset password',
      cancel: 'Cancel',
    },
    linkSentTitle: 'Email sent',
    linkSent:
      'We’ve sent an email to the provided email address with further instructions. You can close this window.',
  },

  // products
  productsPage: {
    pageTitle: 'Products',
    favoriteProducts: 'Favorite products',
    search: 'Search',
    showing: {
      pre: 'Showing',
      post: 'Product',
      plural: 'Products',
      out: 'out of',
      none: 'No products',
    },
    categories: {
      singleOils: 'Single Oils',
      blends: 'Blends',
      supplements: 'Supplements',
      personalCare: 'Personal Care',
      household: 'Household',
      oilKits: 'Kits',
      diffusers: 'Diffusers',
      accesories: 'Accesories',
    },
    extractionMethod: 'Extraction method',
    description: 'Description',
    ingredients: 'Ingredients',
    benefits: 'Benefits',
    cautions: 'Cautions',
    translatedBy: 'Credits',
    toastMessage: {
      addProduct: 'Product added to Favorites',
      removeProduct: 'Product removed from Favorites',
    },
  },
  // single product page
  productPage: {
    breadcrumbs: {
      all: 'All Products',
    },

    editProduct: 'Edit product',
    extractionMethod: 'Extraction method',
    description: 'Description',
    ingredients: 'Ingredients',
    benefits: 'Benefits',
    cautions: 'Cautions',
    directionsOfUse: 'Directions of use',
    translated: 'Adapted and Translated By',
    sources: 'Sources',
    disclaimer: 'Disclaimer',
    disclaimerInfo:
      'This information is a compilation of suggestions made by those who have used essential oils and do not intend to substitute the advice of a medical doctor. It is an informative document from personal experience and should be treated as such. The recommended products and methods are not intended to diagnose, treat, cure or prevent any disease, nor are they intended to replace appropriate medical care',
    extraction: {
      none: 'None',
      notApplicable: 'Not Applicable',
      steamDistillationOfFlowers: 'Steam distillation of flowers',
      steamDistillationOfLeaves: 'Steam distillation of leaves',
      steamDistillationOfFruit: 'Steam distillation of fruit',
      steamDistillationOfRoots: 'Steam distillation of roots',
      steamDistillationOfRhizomes: 'Steam distillation of rhizomes',
      steamDistillationOfWood: 'Steam distillation of wood',
      steamDistillationOfResin: 'Steam distillation of resin',
      steamDistillationOfSap: 'Steam distillation of sap',
      steamDistillationOfSeeds: 'Steam distillation of seeds',
      steamDistillationOfBark: 'Steam distillation of bark',
      steamDistillationOfLeavesAndFlowers:
        'Steam distillation of leaves and flowers',
      steamDistillationOfNeedlesAndTwigs:
        'Steam distillation of needles and twigs',
      steamDistillationOfFruitsLeavesAndSeeds:
        'Steam distillation of fruits, leaves and seeds',
      steamDistillationOfLeavesAndYoungBranches:
        'Steam distillation of leaves and young branches',
      steamDistillationOfLeavesAndPlantTips:
        'Steam distillation of leaves and plant tips',
      steamDistillationOfLeavesAndStems:
        'Steam distillation of leaves and stems',
      steamDistillationOfTheFloweringPartOfThePlant:
        'Steam distillation of the flowering part of the plant',
      steamDistillationOfFlowerBudsStemsAndLeaves:
        'Steam distillation of flower buds, stems and leaves',
      steamDistillationOfLeavesSeedsSapAndBark:
        'Steam distillation of leaves, seeds, sap and bark',
      steamDistillationOfBudsAndStems: 'Steam distillation of buds and stems',
      steamDistillationOfFlowersBudsAndLeaves:
        'Steam distillation of flowers, buds and leaves',
      coldPressingOfFruitPeel: 'Cold pressing of fruit peel',
      hydroDistillationFromRoots: 'Hydro Distillation from roots',
      absoluteFromFlowers: 'Absolute from flowers',
      absoluteFromBeansOrPods: 'Absolute from beans/pods',
    },
  },
  // my landing page
  landingPage: {
    footer: {
      text: 'Welcome home. Welcome to Wonderland Essentials. A world full of doTERRA essentials oils and magic - the magic of becoming. ',
      doTERRAWebsite: 'Official doTERRA website',
      cookie: 'Cookie Policy',
      privacy: 'Privacy Policy',
      terms: 'Terms and Conditions',
      poweredBy: 'Powered by',
      content: 'Wonderland Essentials. All rights reserved',
      disclaimer: 'Disclaimer',
    },
  },
  // events
  eventsPage: {
    pageTitle: 'Events',
  },
  // single event page
  eventPage: {
    breadcrumbs: {
      all: 'All Events',
    },
    editEvent: 'Edit event',
  },

  // protocols
  protocolsPage: {
    pageTitle: 'Protocols',
    favoriteText: 'Show only favorites',
    favoriteProtocols: 'Favorite protocols',
    search: 'Search',
    showing: {
      pre: 'Showing',
      mid: 'of',
      post: 'Protocol',
      plural: 'Protocols',
      none: 'No protocols',
    },
    categories: {
      all: 'All',
      physical: 'Physical',
      emotional: 'Emotional',
    },
    description: 'Description',
    ingredients: 'Ingredients',
    benefits: 'Benefits',
    cautions: 'Cautions',
    toastMessage: {
      addProtocol: 'Protocol added to Favorites',
      removeProtocol: 'Protocol removed from Favorites',
    },
  },

  // single protocol page
  protocolPage: {
    breadcrumbs: {
      all: 'All Protocols',
    },
    editProtocol: 'Edit protocol',
    physical: 'Physical',
    emotional: 'Emotional',
    cautions: 'Cautions',
    info: '  If you have any questions about this protocol, please contact your consultant.',
    disclaimer: 'Disclaimer',
    applicationZones: 'Application zones',
    topicalRecommendation: 'Topical Recommendation',
    aromaticalRecommendation: 'Aromatical Recommendation',
    internalRecommendation: 'Internal Recommendation',
    additionalRecommendations: 'Additional Recommendations',
    protocolDisclamer:
      '*This information is a compilation of suggestions made by those who have used essential oils and do not intend to substitute the advice of a professional doctor. It is an informative document from personal experience and should be treated as such. The recommended products and methods are not intended to diagnose, treat, cure or prevent any disease, nor are they intended to replace appropriate medical care.',
    physicalTopicalRecommendation: 'Physical Topical Recommendation',
    physicalAromaticalRecommendation: 'Physical Aromatical Recommendation',
    physicalInternalRecommendation: 'Physical Internal Recommendation',
    physicalAdditionalRecommendation: 'Physical Additional Recommendations',
    addPhysicalProtocol: 'Add Physical Protocol',
    emotionalTopicalRecommendation: 'Emotional Topical Recommendation',
    emotionalAromaticalRecommendation: 'Emotional Aromatical Recommendation',
    emotionalInternalRecommendation: 'Emotional Internal Recommendation',
    emotionalAdditionalRecommendations: 'Emotional Additional Recommendations',
  },
  // 404 not found page
  notFoundPage: {
    notFound: 'Page not found',
    title:
      'It looks like the page you are searching for has been moved or isn’t available anymore.',
    content: "We hope it's not an essential oil bottle ...",
    button: 'Back to Homepage',
  },
  // contact page
  contactPage: {
    title: 'Let’s get in touch',
    text: 'Fill in the form. It’s super easy.',
    content: 'You can also reach us by email at ',
    website: 'support@wonderlandessentials.com',
    contactEmail: 'mailto:support@wonderlandessentials.com',
    button: 'Send Message',
    typeEmail: 'Email Address',
    textAreaLabel: 'Briefly tell us how can we help you',
    messageSent: 'Your message was sent.',
  },
  // request error messages
  errors: {
    auth: {
      login: {
        notFound: 'User doesn’t exist',
        wrongPass: 'Incorrect password.',
        generic: 'Something went wrong. Please try again or contact us.',
      },
    },
    products: {
      badRequest: 'Bad request.',
      notFound: 'Product not found.',
    },
    protocols: {
      badRequest: 'Bad request.',
      notFound: 'Protocol not found.',
    },
    events: {
      badRequest: 'Bad request.',
      notFound: 'Event not found.',
    },
    users: {
      badRequest: 'Bad request.',
      notFound: 'User not found.',
    },
  },
  //consultations page
  consultations: {
    title: 'Consultations',
    subTitle: '15-minute FREE Consultation with Alice Vacian',
    info: 'In the free consultation sessions conducted in English, expect a safe and intimate space to share your concerns with a tailored essential oil blend for support from Alice. These sessions offer a brief opportunity to gain insight and experience the benefits of essential oils.',
    important:
      'IMPORTANT! Before booking a consultation, make sure of the following:',
    list: {
      item1: 'You are in a quiet environment',
      item2: 'You have something to take notes on',
      item3: 'You have one question, so ask the most pressing one',
      item4: 'You have ZOOM installed, and your mic and camera are working',
      item5: {
        firstSpan:
          "If you can't make it to our meeting, please let us know in advance at ",
        secondSpan: 'alice@wonderlandessentials.com',
      },
      item6:
        'Limit consultations to one per month to ensure equal enjoyment due to the high demand',
    },
    button: 'Continue to booking',
  },
  dashboard: {
    greetings: 'Hi',
    title: 'Dashboard',
    blendOfTheMonth: 'Blend of the month',
    viewBlends: 'View all blends',
    noBlends: 'No blends',
    monthlyOffer: 'Monthly offer',
    upcomingEvent: 'Upcoming Events',
    latestArticles: 'Latest articles',
  },

  //blend of the month
  blendDetailPage: {
    title: 'Blend of the month',
    allBlend: 'All Blend of the month',
    frequency: 'Frequency',
    duration: 'Duration',
    cautions: 'Cautions',
    applicationZones: 'Application zones',
    information: 'Important Information',
    informationContent:
      'PLEASE DO NOT SHARE THIS BLEND OUTSIDE OF OUR COMMUNITY. These blends are specifically designed for you who already have a spiritual practice. I hold space for those on our team who use these blends. Emotional difficulties could be challenging to manage if someone applies without proper support.',
    disclaimer: 'Disclaimer',
    disclaimerContent:
      'This information is a compilation of suggestions made by those who have used essential oils and do not intend to substitute the advice of a medical doctor. It is an informative document from personal experience and should be treated as such. The recommended products and methods are not intended to diagnose, treat, cure or prevent any disease, nor are they intended to replace appropriate medical care.',
    edit: 'Edit blend',
  },

  // eduCare page
  eduCarePage: {
    pageTitle: 'eduCare',
    allArticles: 'All articles',
    search: 'Search eduCare',
    title1: 'Discover the world of ',
    title2: 'doTERRA essential oils',
    description:
      'Welcome to our "eduCare" section, where we provide educational resources on doTERRA essential oils. Our articles cover the basics of essential oils, including their uses, benefits, and safety considerations. We also provide tips on using essential oils for specific health concerns and incorporating them into your daily routine.',
    subTitle: 'In-depth articles for curious minds',
    startBasics: 'Start with the Basics',
    editTitle: 'Edit Article',
    readTime: 'min read',
  },

  // application zones
  applicationZones: {
    solesOfFeet: 'Soles of Feet',
    neck: 'Neck',
    forhead: 'Forehead',
    armpits: 'Armpits',
    solarPlexus: 'Solar Plexus',
    heart: 'Heart',
    sacrum: 'Sacrum',
    lombarRegion: 'Lombar Region',
    sinuses: 'Sinuses',
    underTheNose: 'Under the Nose',
    chest: 'Chest',
    backOfNeck: 'Back of Neck',
    spine: 'Spine',
    diaphragm: 'Diaphragm',
    back: 'Back',
    topOfHead: 'Top of Head',
    aroundBellyButton: 'Around Belly Button',
    lowerAbdomen: 'Lower Abdomen',
    insideWristCreases: 'Inside Wrist Creases',
    temples: 'Temples',
    baseOfTheSkull: 'Base of Skull',
    behindTheEars: 'Behind the Ears',
    alongTheArms: 'Along the Arms',
    abdomen: 'Abdomen',
    shoulders: 'Shoulders',
    kidneys: 'Kidneys',
    midlineOfTheBelly: 'Midline of the Belly',
    insideElbowCreases: 'Inside Elbow Creases',
    outerLineOfTheJaw: 'Outer Line of the Jaw',
    sternBone: 'Sternum',
    outsideWristCreases: 'Outside Wrist Creases',
    innerThighArea: 'Inner Thigh Area',
    theBaseOfTheNeck: 'The Base of the Neck',
    mastoid: 'Mastoid',
    feet: 'Feet',
    behindTheKnees: 'Behind the Knees',
    tailBone: 'Tail Bone',
    liverArea: 'Liver Area',
    adrenals: 'Adrenals',
    thirdEye: 'Third eye',
    noseWings: 'Nose Wings',
    thymus: 'Thymus',
    ankles: 'Ankles',
    bitToes: 'Big Toes',
  },
};
